import ErrorPage from '../ErrorPage';
import { myPaymentsAndInvoicesRoute } from '../../my-account/payment-and-invoices/route'
import { myRenewRoute } from '../../my-account/renew/route';
import { useNcassAuth0 } from '../../../../hooks/useNcassAuth0';
import ErrorPageContactUs from '../PageContactUsError';
import { wordpressContactUsRoute } from '../../external-routes';
import ApiResource from '../../../../components/ApiResource';
import DefaultRenderError from '../../../../components/dynamics';
import { MemberDetailsInfo } from '../../../../components/my-account/types';
import { PaymentTypes } from '../../../../components/my-account/member-payments/types';
import { logOutWithAuth0 } from '../../../../components/Logout';

const MembershipStatus = (): JSX.Element => {

    const { user } = useNcassAuth0();
    const { logout } = useNcassAuth0();
    
    // Bypass membership status checking if member is attempting to log out
    if (window.location.href.includes("/account/logout")) {
        const urlParams = new URLSearchParams(window.location.search);
        const remote = urlParams.get('remote');
        logOutWithAuth0(logout, remote==='true');
    } 

    if (user.ncassAccountStatus === 'Archived') { return Renew('archived') }

    if (user.ncassAccountStatus === 'Cancelled') { return Renew('cancelled') }

    if (user.ncassAccountStatus === 'Expired') { return Renew('expired') }

    if (user.ncassAccountStatus === 'Suspended') { return Suspended('suspended') }

    return ErrorPageContactUs();
};

const Renew = (title: string) => {    
    const { user } = useNcassAuth0();
    return  <ApiResource resource={`/members/${user.membershipNumber}/details`} renderError={DefaultRenderError}>
                {(memberInfo: MemberDetailsInfo) => {
                    const descriptionText = 'Please click below to view your renewal options and to regain full access to your dashboard.';

                    // handles case where Auth0 says Cancelled/Expired/Suspended but Dynamics says 'Current'. This will happen when member logs in, but renews over the phone.
                    const alreadyRenewedComponent = <>
                        <p>{descriptionText}</p>
                        <p className='font-larger'>
                            <b>
                            If you have already renewed over the phone, please wait a few minutes for this to update and then log out using the button below. Then log back in to refresh your membership status and start using the site again.</b>
                        </p>
                    </>
                    
                    if (memberInfo?.latestMembership?.paymentType?.name === PaymentTypes.CardPayment 
                        && user["http://ncass.org.uk/memberType"] === 'Caterer' 
                        && memberInfo.balance === 0) {
                        return <ErrorPage
                            title={`Membership ${title}.`}
                            heading={`Oops! Your account is currently ${title}.`}
                            description={memberInfo?.latestMembership?.status == 'Current' ? alreadyRenewedComponent : descriptionText}
                            buttonConfiguration={{ path: myRenewRoute.path, text: 'Renew Membership' }} />
                    } else {
                        return ContactDescriptionRestartMembership(title);
                    }

                }}
            </ApiResource>
}
const ContactDescriptionRestartMembership = (title: string) => {
    return <ErrorPage
        title={`Membership ${title}.`}
        heading={`Oops! Your account is currently ${title}.`}
        description='Click the link below to contact us and restart your membership '
        buttonConfiguration={{ path: wordpressContactUsRoute.url, text: 'Contact Us' }} />
}

const Suspended = (title: string) => {
    return <ErrorPage
        title={`Membership ${title}.`}
        heading="Oops! You don't currently have permission to view this page."
        description='This may be because your membership is suspended due to an overdue payment.'
        buttonConfiguration={{ path: myPaymentsAndInvoicesRoute.path, text: 'Go to Payments Page' }} />
}

export default MembershipStatus;
