import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from 'reactstrap';

import { toast } from 'react-toastify';

import useApi from '../../../hooks/useApi';
import MissingCompanyDetailsForm from './MissingCompanyDetailsForm';
import { getCompanyTypeName } from '../../../components/my-account/my-details/my-account-utils';
import { MemberDetailsInfo, MissingCompanyDetails, CompanyTypeOption } from '../../../components/my-account/types';
import { useMembershipInfoContext } from "../../../components/MembershipInfo/MembershipInfoContext";
import { companyNumberNotNeeded, companyNumberRegexTest } from '../../../components/my-account/my-details/my-details-validation';

export type MissingCompanyDetailsFormFields = {
    companyTypeId: number
    companyRegistrationOrCharityNumber: string,
    vatRegistered: "true" | "false" | null
}

type MissingCompanyDetailsModalProps = {
    showModal: boolean;
    closeModal: () => void;
}

const MissingCompanyDetailsModal = ({ showModal, closeModal }: MissingCompanyDetailsModalProps) => {
    
    const { getApi } = useApi();
    const { memberId, needCompanyDetails, membershipInfo, setMembershipInfo, companyTypes, setFormState, formState } = useMembershipInfoContext();

    const companyTypeName = getCompanyTypeName(membershipInfo?.companyTypeId, companyTypes);

    const methods = useForm<MissingCompanyDetailsFormFields>({
        defaultValues: {
            companyTypeId: membershipInfo?.companyTypeId,
            companyRegistrationOrCharityNumber: membershipInfo?.companyRegistrationOrCharityNumber
        },
    });

    if (!needCompanyDetails) return null;

    const onSubmit = methods.handleSubmit(async (data: MissingCompanyDetailsFormFields) => {
        setFormState('submitted');
        const companyType = companyTypes.filter(ct => ct.id === data.companyTypeId)[0];
        const businessNotRegisteredId = companyTypes.filter(x => x.text === CompanyTypeOption.BusinessNotRegistered.toString())[0]?.id;
        const companyTypeIsInvalid = !companyType || companyType.id === businessNotRegisteredId;

        const companyNumberFieldIsInvalid = (!companyNumberNotNeeded(companyType.text) && !companyNumberRegexTest(data.companyRegistrationOrCharityNumber, companyType.text === 'Charity'));

        if (companyTypeIsInvalid || companyNumberFieldIsInvalid || data.vatRegistered === null) {
            setFormState('invalid');
            return;
        }

        const updatedVatRegistered: boolean = data.vatRegistered.toString() === 'true';

        const updatedMembershipInfo: MemberDetailsInfo = {
            ...membershipInfo, ...data,
            vatRegistered: updatedVatRegistered,
            companyType: { id: companyType.id, name: companyType.text }
        };
        const updatedCompanyDetails: MissingCompanyDetails = {
            companyTypeId: companyType.id,
            companyRegistrationOrCharityNumber: data.companyRegistrationOrCharityNumber,
            vatRegistered: updatedVatRegistered
        };

        const errorMessage = "There was an error saving your company details.";

        getApi().then((api) => {
            api.url(`/members/${memberId}/details/company-type`)
                .put(updatedCompanyDetails)
                .badRequest((err) => {
                    console.error(err);
                    toast.error(errorMessage);
                })
                .json(() => {
                    toast.success('Your details have been successfully updated.');
                    setFormState('clean');
                    setMembershipInfo(updatedMembershipInfo);
                    setTimeout(() => {
                        closeModal();
                    }, 1500)
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(errorMessage);
                }
                );
        });

    });

    return <Modal isOpen={showModal}>
        <ModalHeader>
            Please Enter Your Company Details
        </ModalHeader>
        <ModalBody>
            <FormProvider  {...methods}>
                <Form onSubmit={onSubmit}>
                    <MissingCompanyDetailsForm
                        companyTypeId={membershipInfo?.companyTypeId}
                        companyTypeName={companyTypeName}
                        companyRegistrationOrCharityNumber={membershipInfo?.companyRegistrationOrCharityNumber}
                    />
                </Form>
            </FormProvider>
        </ModalBody>
        <ModalFooter>
            <Button
                id='close'
                color='secondary'
                onClick={closeModal}
                className='ms-1 me-3'
            >
                Close
            </Button>
            <Button
                id='dontShowAgain'
                className='me-1'
                color='primary'
                onClick={onSubmit}
                disabled={formState === 'clean'}>
                {formState !== 'submitted' ? 'Save' : <><Spinner size='sm' /> Saving Changes...</>}
            </Button>
        </ModalFooter>
    </Modal>



}

export default MissingCompanyDetailsModal;