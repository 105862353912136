const settings = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT as string,
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE as string,
  APPLICATION_INSIGHTS_INSTRUMENTATIONKEY: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATIONKEY as string,
  APPLICATION_INSIGHTS_INGESTIONENDPOINT: process.env.REACT_APP_APPLICATION_INSIGHTS_INGESTIONENDPOINT as string,
  APPLICATION_INSIGHTS_LIVEENDPOINT: process.env.REACT_APP_APPLICATION_INSIGHTS_LIVEENDPOINT as string,
  VERSION: process.env.REACT_APP_VER || '0.1.0.0' as string,
  API_URL: process.env.REACT_APP_API_URL as string,
  SHOW_LAYOUT: process.env.REACT_APP_SHOW_LAYOUT as string,
  NCASS_TERMS_AND_CONDITIONS_URL: process.env.REACT_APP_NCASS_TERMS_AND_CONDITIONS_URL as string,
  CASHBACK_TERMS_URL: process.env.REACT_APP_CASHBACK_TERMS_URL as string,
  DIGITAL_SMS_URL: process.env.REACT_APP_DIGITAL_SMS_URL as string,
  CONTROL_PANEL_URL: process.env.REACT_APP_CONTROL_PANEL_URL as string,
  OFFICE_PHONE_NUMBER: process.env.REACT_APP_OFFICE_PHONE_NUMBER as string,
  CONTACT_US_URL: process.env.REACT_APP_CONTACT_US_URL as string,
  CALL_BACK_URL: process.env.REACT_APP_CALL_BACK_URL as string,
  LEGACY_MVC_URL: process.env.REACT_APP_LEGACY_MVC_URL as string,
  REMOTE_LOGOUT_URL: process.env.REACT_APP_REMOTE_LOGOUT_URL as string,
  SHOW_CPA_EXPIRE_BANNER_IN: parseInt(process.env.REACT_APP_SHOW_CPA_EXPIRE_BANNER_IN) as number,

  AUTH0: {
    DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
    CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE as string,
    MEMBER_CHANGE_PASSWORD_URL: process.env.REACT_APP_AUTH0_MEMBER_CHANGE_PASSWORD_URL as string,
    MEMBER_CHANGE_PASSWORD_DB_CONNECTION: process.env.REACT_APP_AUTH0_MEMBER_CHANGE_PASSWORD_DB_CONNECTION   as string,
    MEMBER_CHANGE_PASSWORD_CLIENT_ID: process.env.REACT_APP_AUTH0_MEMBER_CHANGE_PASSWORD_CLIENT_ID  as string,
    LOGOUT_URL: process.env.REACT_APP_AUTH0_LOGOUT_URL as string
  },

  REWARDS: {
    BOOKER: {
      DEAL_URL: process.env.REACT_APP_NCASS_BOOKER_DEAL_URL as string,
      FAQ_URL: process.env.REACT_APP_NCASS_BOOKER_FAQ_URL as string,
    },

    CALOR: {
      APPROVE_REJECTED_CLAIM_CUTOFF_DAYS: parseInt(process.env.REACT_APP_CALOR_APPROVE_REJECTED_CLAIM_CUTOFF_DAYS) as number,
      DEAL_URL: process.env.REACT_APP_CALOR_DEAL_URL as string,
      FAQ_URL: process.env.REACT_APP_CALOR_FAQ_URL as string,
    },

    BIOPAK: {
      DEAL_URL: process.env.REACT_APP_BIOPAK_DEAL_URL as string,
      FAQ_URL: process.env.REACT_APP_BIOPAK_FAQ_URL as string,
    },

    NISBETS: {
      DEAL_URL: process.env.REACT_APP_NISBETS_DEAL_URL as string,
      FAQ_URL: process.env.REACT_APP_NISBETS_FAQ_URL as string,
    },
  },

  SOTPAY: {
    SOTPAY_URL: process.env.REACT_APP_SOTPAY_URL,
    SOTPAY_API_KEY: process.env.REACT_APP_SOTPAY_API_KEY, 
  },

  HELP_VIDEOS: {
    FOOD_SAFETY_ID: process.env.REACT_APP_HELP_VIDEOS_FOOD_SAFETY_ID as string,
    H_AND_S_FIRE_ID: process.env.REACT_APP_HELP_VIDEOS_H_AND_S_FIRE_ID as string,
    HEALTH_SAFETY_POLICY_ID: process.env.REACT_APP_HELP_VIDEOS_HEALTH_SAFETY_POLICY_ID as string,
    COSHH_ASSESSMENT_ID: process.env.REACT_APP_HELP_VIDEOS_COSHH_ASSESSMENT_ID as string,
  },

  TRADING_UNITS: {
    OTHER_BUSINESS_TYPE_ID: process.env.REACT_APP_TRADING_UNITS_OTHER_BUSINESS_TYPE_ID as string
  },

  REALLY_AWESOME_UNIT_AUTOCREATE: {
    PRODUCT_NAME: process.env.REACT_APP_REALLY_AWESOME_PRODUCT_NAME as string,
    BUSINESS_TYPE_ID: process.env.REACT_APP_REALLY_AWESOME_BUSINESS_TYPE as string,
    PREPARED_IN_KITCHEN: process.env.REACT_APP_REALLY_AWESOME_PREPARED_IN_KITCHEN as string,
    FOOD_TYPES: process.env.REACT_APP_REALLY_AWESOME_BUSINESS_FOOD_TYPES as string,
    EQUIPMENT_TYPES: process.env.REACT_APP_REALLY_AWESOME_BUSINESS_EQUIPMENT_TYPES as string
  },

  DISABLE_PAYMENTS: process.env.REACT_APP_DISABLE_PAYMENTS as string
};

export default settings;


