import React, { createContext, useContext, useState } from "react";
import { useNcassAuth0 } from "../../hooks/useNcassAuth0";
import { useMembershipImpersonationContext } from "../MembershipImpersonation/MembershipImpersonationContext"; 
import ApiResource from '../ApiResource';
import { ErrorRenderer } from '../ApiResource/types';
import { MemberDetailsInfo, CompanyTypeOption, CompanyTypeData } from '../my-account/types';
import { NcassUser } from "../../models/auth0/NcassUser";
import ErrorPageContactUs from "../../routes/pages/error/PageContactUsError";
import MembershipStatus from "../../routes/pages/error/PageMembershipStatusError";

export type CompanyDetailsFormState = 'clean' | 'dirty' | 'submitted' | 'invalid';

export type MembershipInfoContextValue = {
    memberId: string
    membershipInfo: MemberDetailsInfo;
    setMembershipInfo: (membershipInfo: MemberDetailsInfo) => void;
    companyTypes: CompanyTypeData[];
    setCompanyTypes: (companyTypes: CompanyTypeData[]) => void;
    needCompanyDetails: (user: NcassUser) => boolean;
    formState: CompanyDetailsFormState,
    setFormState: (state: CompanyDetailsFormState) => void,
}

const MembershipInfoContext = createContext<MembershipInfoContextValue>({
    memberId: null,
    membershipInfo: null,
    setMembershipInfo: (membershipInfo: MemberDetailsInfo) => { },
    companyTypes: [],
    setCompanyTypes: (companyTypes: CompanyTypeData[]) => { },
    needCompanyDetails: (user: NcassUser) => false,
    formState: 'clean',
    setFormState: () => { },
});

const renderError: ErrorRenderer = (error, defaultRenderer) => {
    return <ErrorPageContactUs />
}

export const MembershipInfoProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { user } = useNcassAuth0();
    const { isImpersonatingMember, membershipNumberOverride } = useMembershipImpersonationContext();
    const [formState, setFormState] = useState<CompanyDetailsFormState>('clean');
    const [membershipInfo, setMembershipInfo] = useState<MemberDetailsInfo>();
    const memberId = !!user ? user?.membershipNumber : null;
    const [companyTypes, setCompanyTypes] = useState<CompanyTypeData[]>();

    const onMembershipInfoReceived = (memberDetailsInfo: MemberDetailsInfo) => {
        if (!memberDetailsInfo.companyType) {
            const companyType = companyTypes.filter(ct => memberDetailsInfo.companyTypeId === ct.id)[0]
            memberDetailsInfo.companyType = companyType ? { id: companyType.id, name: companyType.text } : null;
        }

        setMembershipInfo(memberDetailsInfo);
    }

    // Check that member is active in Auth0
    const isActiveMemberAuth0 = (user: NcassUser): boolean => {
        return !!user?.roles && user?.roles.length > 0 && user?.roles.includes('ncassMember') && (user?.ncassAccountStatus === 'Active' || user?.ncassAccountStatus === 'Renew Soon');
    }

    // Check that member is active in Dynamics
    const isActiveMemberDynamics = (membershipInfo?: MemberDetailsInfo): boolean => {
        return membershipInfo?.latestMembership?.status === "Current";
    }

    const needCompanyDetails = (user: NcassUser): boolean => {

        // Skip this check if user is impersonating
        if (isImpersonatingMember) return false;

        // Map the member's company type if it is not mapped or is set to null, if not found
        if (membershipInfo && !membershipInfo.companyType && !!companyTypes && companyTypes.length > 0) {
            const companyType = companyTypes.filter(ct => membershipInfo.companyTypeId === ct.id)[0]
            membershipInfo.companyType = !!companyType ? { id: companyType.id, name: companyType.text } : null;
        }

        // Skip the check if we are missing relevant information or user is not an actuve member
        if (!companyTypes || companyTypes.length === 0 || !user || !isActiveMemberAuth0(user) || !membershipInfo) return false;

        const companyTypeName: string | null = getCompanyTypeName(membershipInfo);
        const missingCompanyType = checkCompanyType(membershipInfo, companyTypeName);
        const noCompanyNumber = !membershipInfo.companyRegistrationOrCharityNumber;
        const companyNumberExpected = shouldHaveCompanyNumber(companyTypeName);

        const missingCompanyNumber = noCompanyNumber && companyNumberExpected;
        const missingVatRegistered = membershipInfo.vatRegistered === undefined || membershipInfo.vatRegistered === null;

        return (missingCompanyType || missingCompanyNumber || missingVatRegistered);
    }

    const checkCompanyType = (membershipInfo: MemberDetailsInfo, companyTypeName: string | null): boolean => {
        return membershipInfo?.companyTypeId === 0 || !companyTypeName || companyTypeName === CompanyTypeOption.BusinessNotRegistered.toLowerCase();
    }

    const getCompanyTypeName = (membershipInfo: MemberDetailsInfo): string | null => {
        return !!membershipInfo?.companyType?.name ?
            membershipInfo?.companyType?.name?.toLowerCase() :
            null;
    }

    const shouldHaveCompanyNumber = (companyTypeName: string | null): boolean => {
        return !!companyTypeName &&
            (companyTypeName.toLowerCase() === CompanyTypeOption.LimitedCompany.toLowerCase() ||
                companyTypeName.toLowerCase() === CompanyTypeOption.Charity.toLowerCase() ||
                companyTypeName.toLowerCase() === CompanyTypeOption.PLC.toLowerCase());
    }

    var contextValue: MembershipInfoContextValue = {
        memberId, membershipInfo, setMembershipInfo, companyTypes, setCompanyTypes, needCompanyDetails, formState, setFormState
    };

    // If we don't have an ncass user object at this point, something very wrong.
    if (!user || user?.roles.length === 0) {
        return <MembershipInfoContext.Provider value={contextValue}>
        {children}
    </MembershipInfoContext.Provider>
    }

    return (
        <ApiResource resource={`/members/reference-data/company-type`} renderError={renderError} onDataReceived={setCompanyTypes}>
            {
                ()  => {

                    // Unsatisfactory check - Auth0 and Dynamics should always be in sync regarding account status, but often aren't, e.g. immediately after renewal.
                    // Make sure member is inactive in both Auth0 and Dynamics before refusing them access to the site
                    // Need to fix the root cause by removing account status from Auth0 and always use Dynamics - work item in backlog to start this work

                    const activeInAuth0 = isActiveMemberAuth0(user);
                    const activeInDynamics = isActiveMemberDynamics(membershipInfo);
                    const notActiveMember = !activeInAuth0 && !activeInDynamics;

                    // Handle expired members (inactive in Auth0 and Dynamics) and allow them to renew
                    if (!isImpersonatingMember && notActiveMember && !!user?.membershipNumber) {
                        return <ApiResource resource={`/members/${user?.membershipNumber}/renewals/can-renew`} renderError={renderError}>
                            {
                                (response: string) => {
                                    return response === "Ok" ? <ApiResource resource={`/members/${user?.membershipNumber}/details`} renderError={renderError} onDataReceived={setMembershipInfo}>
                                        {() => {
                                            return <MembershipInfoContext.Provider value={contextValue}>
                                                {children}
                                            </MembershipInfoContext.Provider>
                                        }
                                        }
                                    </ApiResource> :
                                    <MembershipStatus />;
                                }
                            }
                        </ApiResource>
                    }

                    // Handle partner accounts (Nisbets, BioPak etc), admins, account managers and developers and allow them to skip checking for missing membership details
                    if (!isImpersonatingMember && notActiveMember && !user?.membershipNumber) {
                        return <MembershipInfoContext.Provider value={contextValue}>
                            {children}
                        </MembershipInfoContext.Provider>
                    }

                    // For members active in Dynamics, load the site as usual
                    var membershipNumber = isImpersonatingMember ? membershipNumberOverride : user?.membershipNumber;
                    return <ApiResource resource={`/members/${membershipNumber}/details`} renderError={renderError} onDataReceived={onMembershipInfoReceived}>
                        {
                            () => {
                                return <MembershipInfoContext.Provider value={contextValue}>
                                    {children}
                                </MembershipInfoContext.Provider>
                            }
                        }
                    </ApiResource>
                }
            }
        </ApiResource>
    )
}

export const useMembershipInfoContext = () => useContext(MembershipInfoContext);

