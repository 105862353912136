import { lazy } from "react";
import { RouteConfig } from "../../../../types/RouteConfig";
import { errorMembershipRoute } from "../../../error/PageMembershipStatusError/route";

export const unitPremisesOverviewRoute: RouteConfig<'with-page'> = {
    name: 'Unit/Premises Overview',
    page: lazy(() => import('.')),
    path: '/unit-premises/overview',
    flags: ['member-route'],
    roles: ['ncassAdmin'],
    permissionsRedirect: errorMembershipRoute,
}