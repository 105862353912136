import { RouteConfig } from "../../../types/RouteConfig";
import { defaultFoodSafetyDocumentRoute } from "./default-food-safety/route";

export const defaultDocumentsRoute: RouteConfig<'with-children'> = {
    name: 'Default Documents',
    icon: 'dripicons-document',
    roles: ['ncassAdmin'],
    children: [
        defaultFoodSafetyDocumentRoute
    ]
}